import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import RootLayout from './pages/RootLayout';
import ErrorPage from './pages/ErrorPage';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'
import Loading from './components/ui/Loading.js';

const HomePage = lazy(() => import('./pages/HomePage.js'))

const router = createBrowserRouter([
  {path:'/', element:<RootLayout />,
    children:[
      {path:'/', element:<Suspense fallback={<Loading />}><HomePage /></Suspense>},
    ],
    errorElement:<ErrorPage />
  }
])
function App() {
  return <RouterProvider router={router} fallbackElement={<Loading />} />
}

export default App;
