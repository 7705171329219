import React from 'react'
import { NavLink } from 'react-router-dom'
import classes from './Header.module.css'
// import { TbMenu2 } from "react-icons/tb";
// import logo from '../../logo.svg'
import SmallAbout from '../main/SmallAbout'

const Header = () => {

    const handleNavbarToggler = (event) => {
        let divElement = document.getElementById("navbarTogglerDemo01")
        divElement.setAttribute("class", "navbar-collapse collapse")
    }

  return (
    <nav className={`navbar navbar-expand-lg fixed-top ${classes.header}`} >
        <div className="container">
                <NavLink to="/" className="navbar-brand">
                    {/* <img src={logo} alt="Logo" width="50" height="44"  className="d-inline-block" /> */}
                    <span className={classes.logoText} onClick={handleNavbarToggler}>BLOG</span>
                </NavLink>
                {/* <button type="button" className={classes.togglerbtn} data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span><TbMenu2 style={{width:"30", height:"50", color:'white'}}/></span>
                </button> */}

            <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                <ul className={`navbar-nav ms-auto mb-2 mb-lg-0 ${classes.navbarNav}`} >
                    {/* <li className="nav-item">
                        <NavLink onClick={handleNavbarToggler} className={`nav-link`} to="/" style={({ isActive }) => isActive ? {color:'#353535'} : { color: 'white'}}><small>Home</small></NavLink>
                    </li> */}
                    {/* <li className="nav-item">
                        <NavLink onClick={handleNavbarToggler} className={`nav-link`} to="#" style={({ isActive }) => isActive ? {color:'#353535'} : { color: 'white'}}><span>Link</span>1</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink onClick={handleNavbarToggler} className={`nav-link`} to="#" style={({ isActive }) => isActive ? {color:'#353535'} : { color: 'white'}}><span>Link</span>2</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink onClick={handleNavbarToggler} className={`nav-link`} to="#" style={({ isActive }) => isActive ? {color:'#353535'} : { color: 'white'}}><span>Link</span>3</NavLink>
                    </li> */}
                </ul>
            </div>
            <div>
                <div className={`col-lg-3 col-md-3 col-sm-12`}>
                    <SmallAbout/>
                </div> 
            </div>
        </div>
    </nav>
  )
}

export default Header