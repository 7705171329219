import React from 'react'
import avtaar from '../../assests/images/avtaarimage/avtaar.webp'
import classes from './SmallAbout.module.css'

const SmallAbout = () => {

    return (
        <>
        <div>
            <img rel="preload" src={avtaar} alt="Avatar" className={classes.avatar}/>
        </div>
        </>
    )
}
export default SmallAbout