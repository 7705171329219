import React from 'react'
import { useRouteError } from 'react-router-dom';

const ErrorPage = () => {
  const error = useRouteError();
  let title = '';
  let message = 'Something went wrong!';

  if (error.status === 500) {
    message = JSON.parse(error.data).message;
  }

  if (error.status === 404) {
    title = 'Not found!';
    message = 'Could not find resource or page.';
  }

  return (
    <div className='container text-center mt-5'>
      <h1>{title}</h1>
      <p>{message}</p>
    </div>
  )
}

export default ErrorPage